<template>
  <div class="columns section">
    <div class="column is-6 is-offset-3 box">
      <div v-if="response" class="notification is-warning content">
        <b>{{ response }}</b>
      </div>
      <span v-else>Loading...</span>
    </div>
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'
export default {
  name: 'LoginClever',
  data () {
    return {
      response: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.requestToken()
    })
  },
  methods: {
    requestToken () {
      const code = this.$route.query.code
      console.log(code)
      request('POST', 'clever/auth', { code, subdomain: 'hub' })
        .then((response) => {
          console.log('Response', response)
          window.console.log('Response', response)
          const data = response.data
          /* Checking if error object was returned from the server */
          if (data.error) {
            const errorName = data.error.name
            if (errorName) {
              this.response = errorName === 'InvalidCredentialsError'
                ? 'Username / Password incorrect. Please try again.'
                : errorName
            } else {
              this.response = data.error
            }

            return
          }

          if (data.redirect) {
            console.log('REDIRECT: ' + data.redirect)
            window.location.href = data.redirect
            return
          }

          /* Setting user in the state and caching record to the localStorage */
          if (data.user) {
            const token = data.user.token
            this.$store.commit('SET_USER', data.user)
            this.$store.commit('SET_TOKEN', token)

            console.log('token: ' + token)
            console.log('user: ' + JSON.stringify(data.user))

            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
              window.localStorage.setItem('token', token)
            }

            this.$router.push(data.redirect ? data.redirect : '/')
          }
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 404) {
            // alert('Not found')
            // teacher - create school record + -> subscription page
            // school admin - create school record + -> subscription page
            // district admin / add district
            // student, block.
            this.$router.push('/noaccess')
          } else {
            this.response = 'Connection Error'
          }
        })
    },
    hasSubscription (user) {
      if (user.school == null) {
        console.log('no user')
        return false
      }
      if (user.school.subscriptions == null) {
        console.log('no subs')
        return false
      }
      const subs = user.school.subscriptions
      for (let i = 0; i < subs.length; i++) {
        const expDate = new Date(subs[i].expiry)
        console.log(expDate)
        console.log(subs[i])
        const now = new Date()
        if (now < expDate) {
          return true
        }
      }
      return false
    },
    resetResponse () {
      this.response = ''
    }
  }
}
</script>
